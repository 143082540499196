const axios = require("axios");
const slugify = require("slugify");

let BASE_URL;
if (process.env.NODE_ENV !== "production") {
    BASE_URL = process.env.BASE_URL || "http://localhost:5000";
} else {
    BASE_URL = process.env.BASE_URL || "https://stakepool.fr";
}

export default {
    ssr: true,
    /*
     ** Headers of the page
     */
    head: function() {
        let currentPath = "";
        if (this.route !== undefined && this.route.path !== undefined) {
            currentPath = this.route.path;
        } else if (this.$route !== undefined && this.$route !== undefined) {
            currentPath = this.$route.path;
        }

        return {
            title: "Stakepool.fr - Le Crypto-média indépendant",
            htmlAttrs: {
                lang: "fr-FR",
            },
            meta: [
                {
                    charset: "utf-8",
                },
                {
                    name: "viewport",
                    content: "width=device-width, initial-scale=1, minimum-scale=1, user-scalable=0",
                },
                {
                    hid: "description",
                    name: "description",
                    content: "Notre mission: décoder le monde des cryptomonnaie, en toute indépendance, grâce à vos investissements.",
                },
            ],
            link: [
                {
                    rel: "icon",
                    type: "image/x-icon",
                    href: "/favicon.ico",
                },
                {
                    rel: "canonical",
                    href: "https://stakepool.fr" + currentPath,
                },
                {
                    rel: "stylesheet",
                    integrity: "sha256-+N4/V/SbAFiW1MPBCXnfnP9QSN3+Keu+NlB+0ev/YKQ=",
                    href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css",
                    crossorigin: "anonymous",
                },
            ],
            script: [
                {
                    src: "https://code.jquery.com/jquery-3.5.1.slim.min.js",
                    integrity: "sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj",
                    crossorigin: "anonymous",
                    body: true,
                },
                {
                    src: "https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js",
                    integrity: "sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN",
                    crossorigin: "anonymous",
                    body: true,
                },
                {
                    src: "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.min.js",
                    integrity: "sha384-w1Q4orYjBQndcko6MimVbzY0tgp4pWB4lZ7lr30WKz0vr/aWKhXdBNmNb5D92v7s",
                    crossorigin: "anonymous",
                    body: true,
                },

                // {
                //     src: "https://www.google-analytics.com/analytics.js",
                //     defer: true,
                //     body: true,
                // },
            ],
        };
    },
    /*
     ** Customize the progress-bar color
     */
    loading: {
        color: "#fff",
    },
    /*
     ** Global CSS
     */
    css: ["@/assets/scss/custom-bootstrap.scss"],

    env: {
        baseUrl: BASE_URL,
    },

    /*
     ** Plugins to load before mounting the App
     */
    plugins: [],

    /*
     ** Nuxt.js modules
     */
    modules: [
        "bootstrap-vue/nuxt",
        "@nuxtjs/sitemap",
        "@nuxt/content",
        "@nuxtjs/axios",
        "@nuxtjs/google-analytics",
        [
            "nuxt-lazy-load",
            {
                // These are the default values
                images: true,
                videos: true,
                audios: true,
                iframes: true,
                native: false,
                polyfill: true,
                directiveOnly: false,

                // Default image must be in the static folder
                defaultImage: "/img/default-image.jpg",

                // To remove class set value to false
                loadingClass: "isLoading",
                loadedClass: "isLoaded",
                appendClass: "lazyLoad",

                observerConfig: {
                    // See IntersectionObserver documentation
                },
            },
        ],
    ],

    module: {
        rules: [
            {
                test: /\.s[ac]ss$/i,
                use: ["style-loader", "css-loader", "sass-loader"],
            },
        ],
    },

    bootstrapVue: {
        bootstrapCSS: false,
        bootstrapVueCSS: false,
        componentPlugins: ["NavbarPlugin", "LayoutPlugin", "ButtonPlugin"],
        directivePlugins: ["VBTooltipPlugin"],
    },

    sitemap: {
        hostname: BASE_URL || "http://localhost:5000",
        gzip: true,
        defaults: {
            changefreq: "weekly",
            priority: 1,
            lastmod: new Date(),
        },
        routes: async () => {
            let sources = [BASE_URL + "/_content/actu", BASE_URL + "/_content/articles"];
            let arr = [];

            for (let s of sources) {
                const { data } = await axios.get(s);
                arr = arr.concat(data.map((article) => article.path));
            }

            const { data } = await axios.get(BASE_URL + "/glossary/glossary.json");
            let terms = Array.from(data.terms);
            for (let category in data.extend) {
                const { data } = await axios.get(BASE_URL + `/glossary/glossary-${category}.json`);
                terms = terms.concat(data.terms);
            }

            arr = arr.concat(terms.map((t) => `/definition/${slugify(t.name.toLowerCase())}`));

            return arr;
        },
    },

    /*
     ** Build configuration
     */
    build: {
        /*
         ** You can extend webpack config here
         */
        extend(config, ctx) {
            config.devtool = "#source-map";
        },

        // analyze: {
        //   analyzerMode: 'static'
        // }
    },

    generate: {
        crawler: false,
    },

    googleAnalytics: {
        id: "UA-180526956-1",
    },

    hooks: {
        "content:file:beforeInsert": (document) => {
            if (document.extension === ".md") {
                const { time } = require("reading-time")(document.text);

                document.readingTime = time;
            }
        },
    },
};

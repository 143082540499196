let tocToSideBarMenu = function(toc) {
    let menu = [];

    let lastDepth2 = null,
        lastDepth3 = null,
        lastDepth4 = null;

    for (let item of toc) {
        if (item.depth == 2) {
            lastDepth2 = {
                title: item.text,
                href: "#" + item.id,
            };
            menu.push(lastDepth2);
        } else if (item.depth == 3) {
            if (lastDepth2) {
                if (!lastDepth2.child) {
                    lastDepth2.child = [];
                }

                lastDepth3 = {
                    title: item.text,
                    href: "#" + item.id,
                };

                lastDepth2.child.push(lastDepth3);
            }
        } else if (item.depth == 4) {
            if (lastDepth3) {
                if (!lastDepth3.child) {
                    lastDepth3.child = [];
                }

                lastDepth4 = {
                    title: item.text,
                    href: "#" + item.id,
                };

                lastDepth3.child.push(lastDepth4);
            }
        }
    }

    return menu;
};

export const buildNewsMenu = async ($content, toc) => {
    const newsList = await $content("actu")
        .only(["title", "path"])
        .sortBy("date", "desc")
        .limit(5)
        .fetch();

    let menu = [];

    if (toc) {
        menu.push({
            header: true,
            title: "Sommaire",
            hiddenOnCollapse: true,
        });

        menu = menu.concat(tocToSideBarMenu(toc));
    }

    menu.push({
        header: true,
        title: "Dernières actualités",
        hiddenOnCollapse: true,
    });

    for (let news of newsList) {
        menu.push({
            title: news.title,
            href: news.path,
        });
    }

    const articleList = await $content("articles")
        .only(["title", "path"])
        .sortBy("date", "desc")
        .limit(10)
        .fetch();

    menu.push({
        header: true,
        title: "Dernières analyses",
        hiddenOnCollapse: true,
    });

    for (let article of articleList) {
        menu.push({
            title: article.title,
            href: article.path,
        });
    }

    return menu;
};
